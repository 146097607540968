import React from "react";
const colors = [
  "hsl(6, 69%, 44%)",
  "hsl(34, 69%, 44%)",
  "hsl(127, 69%, 44%)",
  "#064E3B",
];
// const colors = ["#EF4444", "#F59E0B", "#10B981"];

const scoreColor = (score, maxScore) => {
  const ratio = score / maxScore;
  if (score === -1) {
    return "hsl(0, 0%, 45%)";
  } else if (ratio >= 0.85) {
    return colors[3];
  } else if (ratio > 0.6) {
    return colors[2];
  } else if (ratio > 0.3) {
    return colors[1];
  } else {
    return colors[0];
  }
};

const ScoreCircle = ({
  scoreText,
  score,
  maxScore,
  sqSize,
  strokeWidth,
  width,
  height,
  textSize,
}) => {
  const radius = (sqSize - strokeWidth) / 2;
  const viewBox = `0 0 ${sqSize} ${sqSize}`;
  const dashArray = radius * Math.PI * 2;
  const dashOffset =
    score >= 0 ? dashArray - (dashArray * score) / maxScore : dashArray;

  return (
    <svg
      width={sqSize}
      height={sqSize}
      viewBox={viewBox}
      style={{
        height: height,
        width: width,
      }}
    >
      <circle
        style={{
          fill: "none",
        }}
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        stroke={"#ddd"}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className="circle-progress"
        cx={sqSize / 2}
        cy={sqSize / 2}
        r={radius}
        stroke={scoreColor(score, maxScore)}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${sqSize / 2} ${sqSize / 2})`}
        style={{
          fill: "none",
          strokeDasharray: dashArray,
          strokeDashoffset: dashOffset,
          strokeLinecap: "round",
          strokeLinejoin: "round",
        }}
      />
      <text
        className="circle-text"
        x="50%"
        y="50%"
        dy=".3em"
        textAnchor="middle"
        fill={scoreColor(score, maxScore)}
        style={{
          fontSize: textSize,
          fontWeight: "bold",
        }}
      >
        {scoreText}
      </text>
    </svg>
  );
};

ScoreCircle.defaultProps = {
  sqSize: 192,
  score: 0,
  maxScore: 100,
  strokeWidth: 24,
  textSize: "52px",
  width: "auto",
  height: "auto",
};

export { ScoreCircle, scoreColor };
