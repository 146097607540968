import React from "react";

export const getHeader = (cause, brand) => {
  switch (cause) {
    case "LGBTQ Rights":
      return `Is ${brand} really LGBTQ friendly?`;
    default:
      return `Does ${brand} really support ${cause}?`;
  }
};

export const getDesc = (cause, brand, score, summary) => {
  if (summary) {
    return summary;
  }

  return `${brand} scores "${score}" on ${cause}`;
}

String.prototype.interpolate = function(params) {
  const names = Object.keys(params);
  const vals = Object.values(params);
  return new Function(...names, `return \`${this}\`;`)(...vals);
}

export const getSubscoreTitle = (flag, brand) => {
  switch (flag) {
    case "Supporting an Inclusive Culture & Corporate Social Responsibility":
      return `Does ${brand} have an LGBTQ inclusive culture?`;
    case "Workforce Protections":
      return `Does ${brand} have inclusive workforce protections?`;
    case "Inclusive Benefits":
      return `Does ${brand} offer LGBTQ friendly benefits to its employees?`;
  default:
    return flag ? flag.interpolate({brand: brand}) : "";
  }
};

export const getSubscoreText = (flag, brand, subscore, maxscore) => {
  const s = subscore === maxscore ? "pos" : subscore === 0 ? "neg" : "avg";

  switch (`${s}-${flag}`) {
    case "pos-Supporting an Inclusive Culture & Corporate Social Responsibility":
      return (
        <p>
          {brand} has shown sustained commitment to diversity through several
          initiatives like training for new hires and employees, executives
          performance tied to diversity goals, and policies that support an
          inclusive culture around gender identity and self expression.
          <br />
          {brand} has employee groups and/or diversity councils within the
          company to support LGBTQ employees, and has several programs around
          hiring, supplier selection, marketing, and philanthropy to support
          LBBTQ individuals, causes, and organizations.
        </p>
      );
    case "pos-Workforce Protections":
      return (
        <p>
          {brand} has inclusive workforce protections, with policies that
          include both sexual orientation and gender identity & expression for
          all operations
        </p>
      );
    case "pos-Inclusive Benefits":
      return (
        <p>
          {brand} has strong and inclusive benefits offering for US Employees.
          <br />
          Health Insurance and other benefits are equivalent when it comes to
          their coverage of spouses or domestic partners for different-sex and
          same-sex couples.
          <br />
          At {brand} Transgender individuals are offered equal health coverage,
          including medically necessary care.
        </p>
      );
    case "avg-Supporting an Inclusive Culture & Corporate Social Responsibility":
      return (
        <p>
          {brand} has shown some commitment to diversity through initiatives
          like:
          <ul>
            <li>
              Training for new hires and employees, executives performance tied
              to diversity goals.
            </li>
            <li>
              Policies that support an inclusive culture around gender identity
              and self expression.
            </li>
            <li>
              Employee groups and/or diversity councils within the company to
              support LGBTQ employees.
            </li>
            <li>
              Other programs around hiring, supplier selection, marketing, and
              philanthropy to support LGBTQ individuals, causes, and
              organizations.
            </li>
          </ul>
        </p>
      );
    case "avg-Workforce Protections":
      return (
        <p>
          {brand} has partially implemented inclusive workforce protections,
          like policies that include both sexual orientation and gender identity
          & expression for all operations.
        </p>
      );
    case "avg-Inclusive Benefits":
      return (
        <p>
          {brand} does provide some inclusive benefits as part of their offering
          for US Employees, in terms of health Insurance equality for spouses or
          domestic partners for different-sex and same-sex couples, or equal
          health coverage for transgender individuals.
        </p>
      );
    case "neg-Supporting an Inclusive Culture & Corporate Social Responsibility":
      return (
        <p>
          {brand} has not shown commitment to diversity. Initiatives like
          training for new hires and employees, executives performance tied to
          diversity goals, and policies that support an inclusive culture around
          gender identity and self expression have not been implemented at this
          company.
          <br />
          {brand} does not have employee groups and/or diversity councils within
          the company to support LGBTQ employees, or other programs around
          hiring, supplier selection, marketing, and philanthropy to support
          LBBTQ individuals, causes, and organizations.
        </p>
      );
    case "neg-Workforce Protections":
      return (
        <p>
          {brand} has not implemented inclusive workforce protection. Policies
          that include both sexual orientation and gender identity & expression
          for all operations are not in place at this company
        </p>
      );
    case "neg-Inclusive Benefits":
      return (
        <p>
          {brand} does not provide inclusive benefits offering for US Employees.
          <br />
          Health Insurance and other benefits are not equivalent when it comes
          to their coverage of spouses or domestic partners for different-sex
          and same-sex couples.
          <br />
          At {brand} Transgender individuals are not offered equal health
          coverage, including medically necessary care.
        </p>
      );
    default:
      return subscore && subscore.interpolate !== undefined ? subscore.interpolate({brand: brand}) : "";
  }
};

export const getCta = (cause) => {
  switch (cause) {
  case "LGBTQ Rights":
    return "Instead of searching, get our Chrome extension to discover LGBTQ+ friendly brands automatically!";
  case "Voting Rights":
        return "Instead of searching, get our Chrome extension to discover brands supporting voting rights automatically!";
  case "Women's Rights":
    return "Instead of searching, get our Chrome extension to discover brands supporting women's rights automatically!";
  case "Animal Rights":
    return "Instead of searching, get our Chrome extension to discover cruelty-free brands automatically!";
  case "Sustainability":
    return "Instead of searching, get our Chrome extension to discover sustainable brands automatically!";
  default:
    return `Instead of searching, get our Chrome extension!`;
  }
}
