exports.slugify = function (str) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç'·/_,:;";
  var to = "aaaaaeeeeeiiiiooooouuuunc-------";
  for (var i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
};

exports.imageURL = function (img) {
  const s3prefix = "https://saga-media.s3-us-west-2.amazonaws.com/";
  return s3prefix + img;
};

exports.scoreData = (score) => {
  const parsedScore = parseInt(score.value);

  let textValue = "N/A";
  if (!Number.isNaN(parsedScore)) {
    if (parsedScore <= 100) {
      textValue = "Great";
    }
    if (parsedScore <= 84) {
      textValue = "Good";
    }
    if (parsedScore <= 60) {
      textValue = "Avg";
    }
    if (parsedScore <= 30) {
      textValue = "Poor";
    }
  }

  return {
    textValue,
    parsedScore,
  };
};
