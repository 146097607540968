import React from "react";

const SectionTitle = ({ children }) => (
  <div className="flex gap-4 items-center">
    <h2 className="cb-h2">{children}</h2>
    <div className="h-px bg-gray-900 flex-grow mt-2" />
  </div>
);

export default SectionTitle;
