import React from "react";
import { Helmet } from "react-helmet";
import ogLogo from "../resources/images/og-logo.jpg";

const SEO = ({ title, desc, url, imgurl }) => (
  <Helmet htmlAttributes={{
    lang: 'en',
  }}>
    <title>
      {title}
      {title !== ""
        ? " - Choose Because"
        : "Choose Because - Shop from brands aligned with your values"}
    </title>
    <meta name="description" content={desc} />
    <meta http-equiv="content-language" content="en-en" />
    <meta
      property="og:title"
      content={
        title !== ""
          ? `${title} - Choose Because`
          : "Choose Because - Shop from brands aligned with your values"
      }
    />
    <meta property="og:description" content={desc} />
    <meta property="og:url" content={url} />
    <meta property="og:image" content={imgurl} />
  </Helmet>
);

SEO.defaultProps = {
  title: "",
  desc: `
  We believe that every informed individual has the tremendous power to help make the world a better place through every small decision taken every day. 
  We help our users remain informed by giving them access to relevant information about the causes they care about and the brands they are about to shop from. 
  We do it right when they need it, so they can make better, more informed decisions.
`,
  url: "https://www.choosebecause.com/",
  imgurl: ogLogo,
};

export default SEO;
