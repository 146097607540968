import React from "react";
import AddToChrome from "./add-to-chrome";
import { getCta } from "../copy";
import { Link } from "gatsby";

const mainMenu = [
  { link: "https://www.choosebecause.com", text: "Home" },
  { link: "/", text: "Brands" },
  { link: "https://www.choosebecause.com/about-1", text: "About BeCause" },
  { link: "https://www.choosebecause.com/blog", text: "Updates" },
  { link: "https://www.choosebecause.com/faq", text: "FAQ" },
];

const Layout = ({ cause, children }) => {
  return (
    <div className="bg-bc-sky min-h-screen">
      <header>
        <div className="shadow-md bg-bc-blue px-4 md:px-20 py-4 md:pt-9 md:pb-8">
          <div className=" flex items-center justify-between gap-4 flex-wrap">
            <a
              href="https://choosebecause.com"
              title="Choose Because"
              className="w-full md:w-auto"
            >
              <h1 className="cb-h1" style={{ fontSize: "22px" }}>
                ChooseBeCause
              </h1>
            </a>
            <nav aria-label="Main" className="w-full md:w-auto -ml-2.5 md:ml-0">
              <ul className="flex items-center text-xs md:text-sm font-bold">
                {mainMenu.map((el) => (
                  <li key={el.link}>
                    {el.link.startsWith("https://") ? (
                      <a href={el.link} className="px-2.5 hover:underline">
                        {el.text}
                      </a>
                    ) : (
                      <Link to={el.link} className="px-2.5 hover:underline">
                        {el.text}
                      </Link>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </header>
      <main className="pb-24">{children}</main>
      <Footer cause={cause} />
    </div>
  );
};

const Footer = ({ cause }) => (
  <footer className="bg-bc-blue py-4 fixed bottom-0 w-screen left-0 shadow-up-lg">
    <div className="max-w-screen-xl mx-auto px-4 md:px-10">
      <div className="flex items-center justify-between gap-4">
        <div className="flex-shrink text-white">
          <p>{getCta(cause)}</p>
        </div>
        <AddToChrome />
      </div>
    </div>
  </footer>
);

export default Layout;
