import React from "react";
import { imageURL, scoreData } from "../utils";
import { getHeader } from "../copy";
import ParentBrand from "./parent-brand";
import { ScoreCircle } from "./score-circle";
import useWindowSize from "../components/use-window-size";
import right from "../resources/images/forward.svg";

export const BrandCard = ({ className, score, brand, cause, scoreWidth }) => {
  const title = getHeader(cause.name, brand.name);
  const brandImg = brand?.logo ? imageURL(brand.logo) : null;
  const hasParent = brand?.inheritsScoresFromId !== null;
  const { isMobile } = useWindowSize();
  let summary = score.summary;
  summary.replace("\n", "<br/>");

  return (
    <div
      className={`flex justify-center md:justify-between items-center flex-wrap gap-8 ${className}`}
    >
      <div className={isMobile ? "w-full" : "w-3/4"}>
        <div className="flex items-center gap-4">
          <h1 className="cb-h1 md:order-4">{title}</h1>
          {brandImg && (
            <img
              src={brandImg}
              className="w-10"
              style={{ borderRadius: "100%" }}
              alt=""
            />
          )}
        </div>
        {brand?.website && (
          <a
            href={brand.website}
            target="_blank"
            rel="noreferrer"
            className="text-gray-700 underline text-sm flex gap-0.5 group mt-2"
          >
            {brand.website}
            <img
              src={right}
              alt=""
              className="w-3.5 transition-transform transform group-hover:translate-x-1"
            />
          </a>
        )}
        {hasParent && <ParentBrand brand={brand} cause={cause} />}
        {score.summary && (
          <div className="py-5">
            <p className="leading-snug">{summary}</p>
          </div>
        )}
      </div>
      <ScoreCircle
        scoreText={scoreData(score).textValue}
        score={scoreData(score).parsedScore}
        width={isMobile ? "40%" : "10%"}
      />
    </div>
  );
};
