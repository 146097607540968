import React from "react";
import { Link } from "gatsby";
import { slugify, imageURL } from "../utils";

const ParentBrand = ({ brand, cause }) => (
  <Link
    to={`/${slugify(brand.scoresBrandByInheritsScoresFromId.name)}/${slugify(
      cause.name
    )}/`}
  >
    <div className="inline-flex items-center gap-2 mt-4 font-light text-sm py-2 px-3 rounded-full bg-bc-pink">
      <p>
        {brand.name} {brand.inheritsScoresText}{" "}
        {brand.scoresBrandByInheritsScoresFromId.name}
      </p>
      {brand.scoresBrandByInheritsScoresFromId.logo && (
        <img
          src={imageURL(brand.scoresBrandByInheritsScoresFromId.logo)}
          className="w-4"
          style={{ borderRadius: "100%" }}
          alt=""
        />
      )}
    </div>
  </Link>
);

export default ParentBrand;
