import React from "react";

const AddToChrome = () => (
  <div>
    <a
      className="cb-button"
      href="https://chrome.google.com/webstore/detail/because/kdbijcojbieefjabldpdihadonmfgglp"
      title="Add ChooseBecause to Chrome"
      target="_blank"
      rel="noopener"
    >
      Add to Chrome
    </a>
  </div>
);

export default AddToChrome;
